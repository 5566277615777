window.dark_grid_line_color = "#1a235e";
window.dark_axes_line_color = "#2c3da6";
window.brand_grid_line_color = "#2b5517";
window.brand_axes_line_color = "#49a72c";
window.semi_dark_grid_line_color = "#3f5307";
window.semi_dark__axes_line_color = "#80a714";
Chart.defaults.global.defaultFontColor = "#fff";
Chart.defaults.global.defaultFontFamily = "Montserrat";
//Chart.defaults.scale.gridLines.color = "#343a40";
window.chartColors = {
    red: "rgb(232, 78, 27)",
    redEs: "rgb(171, 50, 0)",
    orange: "rgb(255, 159, 64)",
    orangeEs: "rgb(252, 50, 0)",
    yellow: "rgb(255, 205, 86)",
    yellowEs: "rgb(127, 143, 0)",
    green: "rgb(62, 141, 160)",
    greenRb: "rgb(145, 157, 0)",
    greenEs: "rgb(57, 137, 7)",
    blue: "rgb(32,201,151)",
    blueEs: "rgb(0, 1, 164)",
    purple: "rgb(111,66,193)",
    purpleEs: "rgb(152, 1, 164)",
    grey: "rgb(201, 203, 207)",
    greyEs: "rgb(35, 35, 35)",
    fillPt: "rgb(160,134,180)",
    fillEs: "rgb(103,47,133)",
    fillEn: "rgb(126,195,223)",
    fillEt: "rgb(250,167,74)",
    hidrico: "rgb(64, 88, 51)",
    solar: "rgb(64, 88, 51)",
    eolica: "rgb(142, 190, 83)",
    todas: "rgb(232, 78, 27)",
    simples: "#43a72a",
};

$.datepicker.regional.pt = {
    closeText: "Fechar",
    prevText: "Anterior",
    nextText: "Seguinte",
    currentText: "Hoje",
    monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    dayNames: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    weekHeader: "Sem",
    dateFormat: "dd/mm/yy",
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: "",
};
$.datepicker.setDefaults($.datepicker.regional.pt);
$(function () {
    // Checkbox Action Click
    $(".checkbox-menu").on("change", "input[type='checkbox']", function () {
        $(this).closest("li").toggleClass("active", this.checked);
    });

    // Radio Action Click
    $(".checkbox-menu").on("change", "input[type='radio']", function () {
        $(this).closest("ul").find("li").removeClass("active");
        $(this).closest("li").addClass("active", this.checked);
    });

    // Save Layout User Options
    $(".dropdown-menu .dropdown-item[href*=logout]").on("click", function (e) {
        e.preventDefault();
        saveUserLayout($(this));
    });

    if ($("body").hasClass("brand-layout")) {
        window.grid_line_color = window.brand_grid_line_color;
        window.axes_line_color = window.brand_axes_line_color;
        window.chartColors.simples = window.chartColors.greenRb;
    } else if ($("body").hasClass("semi-dark-layout")) {
        window.grid_line_color = window.semi_dark_grid_line_color;
        window.axes_line_color = window.semi_dark__axes_line_color;
        window.chartColors.simples = window.chartColors.greenEs;
    } else {
        window.grid_line_color = window.dark_grid_line_color;
        window.axes_line_color = window.dark_axes_line_color;
        window.chartColors.simples = window.chartColors.purple;
    }

    $(".dropdown-toggle").on("click", function (e) {
        e.preventDefault();
        $(this).parent().find(".dropdown-menu").hide();
        $(this).parent().find(".dropdown-menu").show("slow");
    });

    let defaultColor = $("#adminmodal").find("pre").parent().css("background-color");
    $("#adminmodal").on("show.bs.modal", function (e) {
        $("#adminmodal").find("pre").parent().css("background-color", "rgb(48, 10, 36)");
    });

    $("#adminmodal").on("hide.bs.modal", function (e) {
        $(this).find(".modal-title").empty();
        $(this).find(".modal-body").empty();
        if (!$(this).find(".modal-dialog").hasClass("modal-xl")) {
            $(this).find(".modal-dialog").addClass("modal-xl");
        }
        $(this).find(".btn-refresh-element").remove();
        $("#adminmodal").find(".modal-body").css("background-color", "");
    });
});

function saveUserLayout(btn) {
    var thislayout = localStorage.layout || "{}";
    let destination = btn[0].href;
    let params = {
        type: "saveLayout",
        layout: thislayout,
    };
    sendRequestTimerURL("/admin/users/update", btn, params.type, params, function () {
        window.location.replace(destination);
    });
}
function appendLeadingZeroes(n) {
    if (n <= 9) {
        return "0" + n;
    }
    return n;
}
function formatdate(MyDate) {
    MyDateString =
        MyDate.getFullYear() +
        "-" +
        ("0" + (MyDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + MyDate.getDate()).slice(-2);
    return MyDateString;
}
function formatdate(MyDate) {
    MyDateString =
        MyDate.getFullYear() +
        "-" +
        ("0" + (MyDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + MyDate.getDate()).slice(-2);
    return MyDateString;
}
function formatdatepicker(MyDate) {
    MyDateString =
        ("0" + MyDate.getDate()).slice(-2) +
        "-" +
        ("0" + (MyDate.getMonth() + 1)).slice(-2) +
        "-" +
        MyDate.getFullYear();
    return MyDateString;
}

window.colors = [
    window.chartColors.blue,
    window.chartColors.greenRb,
    window.chartColors.blueEs,
    window.chartColors.red,
    window.chartColors.redEs,
    window.chartColors.orange,
    window.chartColors.orangeEs,
    window.chartColors.green,
    window.chartColors.greenEs,
    window.chartColors.purple,
    window.chartColors.purpleEs,
    window.chartColors.grey,
    window.chartColors.greyEs,
    window.chartColors.yellow,
    window.chartColors.yellowEs,
];

window.colors2 = [
    window.chartColors.fillPt,
    window.chartColors.fillEs,
    window.chartColors.fillEn,
    window.chartColors.fillEt,
    window.chartColors.hidrico,
    window.chartColors.solar,
    window.chartColors.eolica,
    window.chartColors.todas,
];

window.colors3 = [
    window.chartColors.orangeEs,
    window.chartColors.green,
    window.chartColors.greenEs,
    window.chartColors.purple,
    window.chartColors.purpleEs,
    window.chartColors.grey,
    window.chartColors.greyEs,
    window.chartColors.yellow,
    window.chartColors.yellowEs,
];

window.hidrico = {
    colorAp: window.chartColors.red,
    colorAm: window.chartColors.hidrico,
    colorRCp: window.chartColors.blue,
    colorRCm: window.chartColors.purple,
    colorRIp: window.chartColors.green,
    colorRIm: window.chartColors.grey,
};

window.colors1 = {
    colorAp: window.chartColors.red,
    colorAm: window.chartColors.redEs,
    colorRCp: window.chartColors.blue,
    colorRCm: window.chartColors.purple,
    colorRIp: window.chartColors.green,
    colorRIm: window.chartColors.grey,
};
window.colors2 = {
    colorAp: window.chartColors.orangeEs,
    colorAm: window.chartColors.blue,
    colorRCp: window.chartColors.yellow,
    colorRCm: window.chartColors.purpleEs,
    colorRIp: window.chartColors.yellowEs,
    colorRIm: window.chartColors.greyEs,
};
window.colors3 = {
    colorAp: window.chartColors.purpleEs,
    colorAm: window.chartColors.orange,
    colorRCp: window.chartColors.greyEs,
    colorRCm: window.chartColors.blue,
    colorRIp: window.chartColors.yellow,
    colorRIm: window.chartColors.purple,
};
window.colors4 = {
    colorAp: window.chartColors.greyEs,
    colorAm: window.chartColors.blue,
    colorRCp: window.chartColors.yellowEs,
    colorRCm: window.chartColors.purple,
    colorRIp: window.chartColors.orangeEs,
    colorRIm: window.chartColors.blueEs,
};

/**
 * Configuration for chart
 * @param string    type    Type of chart
 * @param labels    array   Chart X point labels
 * @param datasets  array   Chart Datasets
 * @param string    labelX  Label for X chart
 *
 */
function getConfig(type, labels, datasets, title, labelX, unit = "MWh") {
    config = {
        type: type,
        data: {
            labels: labels,
            datasets: datasets,
        },
        options: {
            legend: {
                labels: {
                    fontColor: "#fff",
                },
            },
            responsive: true,
            title: {
                display: true,
                text: title,
                fontFamily: "Montserrat",
                fontColor: "#fff",
                fontSize: "17",
                fontStyle: "bold",
            },
            tooltips: {
                mode: "index",
                intersect: false,
                backgroundColor: window.grid_line_color,
                titleSpacing: 14,
                bodySpacing: 8,
                callbacks: {
                    label: function (tooltipItem, data) {
                        var label = data.datasets[tooltipItem.datasetIndex].label || "";
                        label = label.split(":")[0];
                        var item = "";
                        if (label) {
                            if (label.includes("Valor") || label.includes("€")) {
                                item = " €";
                            }
                            if (label.includes("Energia")) {
                                item = " " + unit;
                            }
                            label += ": ";
                        }
                        label += parseFloat(tooltipItem.yLabel).toFixed(2) + item;
                        return label;
                    },
                },
            },
            hover: {
                mode: "nearest",
                intersect: true,
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: labelX,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.grid_line_color,
                            drawTicks: true,
                        },
                    },
                ],
                yAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: unit,
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.grid_line_color,
                            drawTicks: true,
                        },
                    },
                ],
            },
        },
    };
    return config;
}

/**
 * Configuration for chart
 * @param string    type    Type of chart
 * @param labels    array   Chart X point labels
 * @param datasets  array   Chart Datasets
 * @param string    labelX  Label for X chart
 *
 */
function getModalConfig(type, labels, datasets, title, labelX, unit = "MWh") {
    config = {
        type: type,
        data: {
            labels: labels,
            datasets: datasets,
        },
        options: {
            legend: {
                labels: {
                    fontColor: "#fff",
                },
            },
            responsive: true,
            title: {
                display: true,
                text: title,
                fontFamily: "Montserrat",
                fontColor: "#fff",
                fontSize: "17",
                fontStyle: "bold",
            },
            tooltips: {
                mode: "index",
                intersect: false,
                backgroundColor: window.grid_line_color,
                titleSpacing: 14,
                bodySpacing: 8,
                callbacks: {
                    label: function (tooltipItem, data) {
                        var label = data.datasets[tooltipItem.datasetIndex].label || "";
                        label = label.split(":")[0];
                        var item = "";
                        if (label) {
                            if (label.includes("Valor") || label.includes("€")) {
                                item = " €";
                            }
                            if (label.includes("Energia")) {
                                item = " " + unit;
                            }
                            label += ": ";
                        }
                        label += parseFloat(tooltipItem.yLabel).toFixed(2) + item;
                        return label;
                    },
                },
            },
            hover: {
                mode: "nearest",
                intersect: true,
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: labelX,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.axes_line_color,
                            drawTicks: true,
                        },
                    },
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: labelX,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.axes_line_color,
                            drawTicks: true,
                        },
                    },
                ],
                yAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: unit,
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.axes_line_color,
                            drawTicks: true,
                        },
                    },
                ],
            },
        },
    };
    return config;
}

/**
 * Configuration for chart
 * @param string    type    Type of chart
 * @param labels    array   Chart X point labels
 * @param datasets  array   Chart Datasets
 * @param string    labelX  Label for X chart
 *
 */
function getConfigDayDMonth(type, labels, datasets, title, labelX, unit = "MWh") {
    config = {
        type: type,
        data: {
            labels: labels,
            datasets: datasets,
        },
        options: {
            responsive: true,
            title: {
                display: true,
                text: title,
                fontColor: "#edeff9",
                fontSize: "17",
                fontStyle: "bold",
                fontFamily: "Montserrat",
                position: "top",
            },
            tooltips: {
                titleFontSize: 14,
                mode: "index",
                intersect: false,
                bodySpacing: 8,
                titleSpacing: 8,
                bodyFontSize: 14,
                backgroundColor: window.grid_line_color,
                foregroundColor: "#ebe717",
                bodyFontColor: "#d3d4ec",
                fontColor: "#d3d4ec",
                xPadding: 10,
                yPadding: 10,
                callbacks: {
                    label: function (item, data) {
                        let idx = item.index;
                        let datasetidx = item.datasetIndex;
                        let label = data.datasets[datasetidx].label;
                        label = label.split(":")[0];
                        let value = item.value;
                        let strValue = parseFloat(value).toFixed(2) + " " + unit;
                        if (label.includes("Custo") || label.includes("€")) {
                            var formatter = new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                            });
                            strValue = formatter.format(value);
                        }
                        return "  " + label + ": " + strValue;
                    },
                },
            },
            hover: {
                mode: "nearest",
                intersect: true,
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: labelX,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.grid_line_color,
                            drawTicks: true,
                        },
                    },
                ],
                yAxes: [
                    {
                        id: "A",
                        type: "linear",
                        position: "left",
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: unit,
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                    {
                        id: "B",
                        type: "linear",
                        position: "right",
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: "€ EUR",
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.grid_line_color,
                            drawTicks: true,
                        },
                    },
                ],
            },
        },
    };
    return config;
}
/**
 * Configuration for chart
 * @param string    type    Type of chart
 * @param labels    array   Chart X point labels
 * @param datasets  array   Chart Datasets
 * @param string    labelX  Label for X chart
 *
 */
function getModalConfigDayDMonth(type, labels, datasets, title, labelX, unit = "MWh") {
    config = {
        type: type,
        data: {
            labels: labels,
            datasets: datasets,
        },
        options: {
            responsive: true,
            title: {
                display: true,
                text: title,
                fontColor: "#edeff9",
                fontSize: "17",
                fontStyle: "bold",
                fontFamily: "Montserrat",
                position: "top",
            },
            tooltips: {
                titleFontSize: 14,
                mode: "index",
                intersect: false,
                bodySpacing: 8,
                titleSpacing: 8,
                bodyFontSize: 14,
                backgroundColor: window.brand_grid_line_color,
                foregroundColor: "#ebe717",
                bodyFontColor: "#d3d4ec",
                fontColor: "#d3d4ec",
                xPadding: 10,
                yPadding: 10,
                callbacks: {
                    label: function (item, data) {
                        let idx = item.index;
                        let datasetidx = item.datasetIndex;
                        let label = data.datasets[datasetidx].label;
                        label = label.split(":")[0];
                        let value = item.value;
                        let strValue = parseFloat(value).toFixed(2) + " " + unit;
                        if (label.includes("Custo") || label.includes("€")) {
                            var formatter = new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                            });
                            strValue = formatter.format(value);
                        }
                        return "  " + label + ": " + strValue;
                    },
                },
            },
            hover: {
                mode: "nearest",
                intersect: true,
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: labelX,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.axes_line_color,
                            drawTicks: true,
                        },
                    },
                ],
                yAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: unit,
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.axes_line_color,
                            drawTicks: true,
                        },
                    },
                ],
            },
        },
    };
    return config;
}
/**
 * Configuration for chart
 * @param string    type    Type of chart
 * @param labels    array   Chart X point labels
 * @param datasets  array   Chart Datasets
 * @param string    labelX  Label for X chart
 *
 */
function getConfigDayD(type, labels, datasets, title, labelX, unit = "MWh") {
    config = {
        type: type,
        data: {
            labels: labels,
            datasets: datasets,
        },
        options: {
            responsive: true,
            title: {
                display: true,
                text: title,
                fontColor: "#edeff9",
                fontSize: "17",
                fontStyle: "bold",
                fontFamily: "Montserrat",
                position: "top",
            },
            tooltips: {
                titleFontSize: 14,
                mode: "index",
                intersect: false,
                bodySpacing: 8,
                titleSpacing: 8,
                bodyFontSize: 14,
                backgroundColor: window.grid_line_color,
                foregroundColor: "#ebe717",
                bodyFontColor: "#d3d4ec",
                fontColor: "#d3d4ec",
                xPadding: 10,
                yPadding: 10,
                callbacks: {
                    label: function (item, data) {
                        let idx = item.index;
                        let datasetidx = item.datasetIndex;
                        let label = data.datasets[datasetidx].label;
                        label = label.split(":")[0];
                        let value = item.value;
                        let strValue = parseFloat(value).toFixed(2) + " " + unit;
                        if (label.includes("Custo") || label.includes("€")) {
                            var formatter = new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                            });
                            strValue = formatter.format(value);
                        }
                        return "  " + label + ": " + strValue;
                    },
                },
            },
            hover: {
                mode: "nearest",
                intersect: true,
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: labelX,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.grid_line_color,
                            drawTicks: true,
                        },
                    },
                ],
                yAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: unit,
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.grid_line_color,
                            drawTicks: true,
                        },
                    },
                ],
            },
            annotation: {
                // Defines when the annotations are drawn.
                // This allows positioning of the annotation relative to the other
                // elements of the graph.
                //
                // Should be one of: afterDraw, afterDatasetsDraw, beforeDatasetsDraw
                // See http://www.chartjs.org/docs/#advanced-usage-creating-plugins
                drawTime: "afterDatasetsDraw", // (default)

                // Mouse events to enable on each annotation.
                // Should be an array of one or more browser-supported mouse events
                // See https://developer.mozilla.org/en-US/docs/Web/Events
                events: ["click"],

                // Double-click speed in ms used to distinguish single-clicks from
                // double-clicks whenever you need to capture both. When listening for
                // both click and dblclick, click events will be delayed by this
                // amount.
                dblClickSpeed: 350, // ms (default)

                // Array of annotation configuration objects
                // See below for detailed descriptions of the annotation options
                annotations: [
                    {
                        type: "line",

                        // optional drawTime to control layering, overrides global drawTime setting
                        drawTime: "afterDatasetsDraw",

                        // optional annotation ID (must be unique)
                        id: "a-line-1",

                        // set to 'horizontal' or 'vertical' to draw a vertical line
                        mode: "vertical",

                        // ID of the scale to bind onto
                        scaleID: "x-axis-0",

                        // Data value to draw the line at
                        value: 1,

                        // Optional value at which the line draw should end
                        //endValue: 1,

                        // Line color
                        borderColor: window.chartColors.simples,

                        // Line width
                        borderWidth: 2,

                        // Line dash
                        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
                        borderDash: [2, 2],

                        // Line Dash Offset
                        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
                        borderDashOffset: 5,

                        label: {
                            // Background color of label, default below
                            backgroundColor: window.chartColors.simples,

                            // Font family of text, inherits from global
                            fontFamily: "sans-serif",

                            // Font size of text, inherits from global
                            fontSize: 12,

                            // Font style of text, default below
                            fontStyle: "bold",

                            // Font color of text, default below
                            fontColor: "#fff",

                            // Padding of label to add left/right, default below
                            xPadding: 6,

                            // Padding of label to add top/bottom, default below
                            yPadding: 6,

                            // Radius of label rectangle, default below
                            cornerRadius: 6,

                            // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
                            position: "center",

                            // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
                            // For horizontal lines positioned left or right, negative values move
                            // the label toward the edge, and positive values toward the center.
                            xAdjust: 0,

                            // Adjustment along y-axis (top-bottom) of label relative to above number (can be negative)
                            // For vertical lines positioned top or bottom, negative values move
                            // the label toward the edge, and positive values toward the center.
                            yAdjust: 0,

                            // Whether the label is enabled and should be displayed
                            enabled: true,

                            // Text to display in label - default is null. Provide an array to display values on a new line
                            content: ["Ontem"],
                        },

                        // Mouse event handlers - be sure to enable the corresponding events in the
                        // annotation events array or the event handler will not be called.
                        // See https://developer.mozilla.org/en-US/docs/Web/Events for a list of
                        // supported mouse events.
                        onMouseenter: function (e) {},
                        onMouseover: function (e) {},
                        onMouseleave: function (e) {},
                        onMouseout: function (e) {},
                        onMousemove: function (e) {},
                        onMousedown: function (e) {},
                        onMouseup: function (e) {},
                        onClick: function (e) {},
                        onDblclick: function (e) {},
                        onContextmenu: function (e) {},
                        onWheel: function (e) {},
                    },
                    {
                        type: "line",

                        // optional drawTime to control layering, overrides global drawTime setting
                        drawTime: "afterDatasetsDraw",

                        // optional annotation ID (must be unique)
                        id: "a-line-2",

                        // set to 'vertical' to draw a vertical line
                        mode: "vertical",

                        // ID of the scale to bind onto
                        scaleID: "x-axis-0",

                        // Data value to draw the line at
                        value: 27,

                        // Optional value at which the line draw should end
                        //endValue: 26,

                        // Line color
                        borderColor: window.chartColors.simples,

                        // Line width
                        borderWidth: 4,

                        // Line dash
                        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
                        borderDash: [2, 2],

                        // Line Dash Offset
                        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
                        borderDashOffset: 5,

                        label: {
                            // Background color of label, default below
                            backgroundColor: window.chartColors.simples,

                            // Font family of text, inherits from global
                            fontFamily: "sans-serif",

                            // Font size of text, inherits from global
                            fontSize: 12,

                            // Font style of text, default below
                            fontStyle: "bold",

                            // Font color of text, default below
                            fontColor: "#fff",

                            // Padding of label to add left/right, default below
                            xPadding: 6,

                            // Padding of label to add top/bottom, default below
                            yPadding: 6,

                            // Radius of label rectangle, default below
                            cornerRadius: 6,

                            // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
                            position: "center",

                            // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
                            // For horizontal lines positioned left or right, negative values move
                            // the label toward the edge, and positive values toward the center.
                            xAdjust: 0,

                            // Adjustment along y-axis (top-bottom) of label relative to above number (can be negative)
                            // For vertical lines positioned top or bottom, negative values move
                            // the label toward the edge, and positive values toward the center.
                            yAdjust: 0,

                            // Whether the label is enabled and should be displayed
                            enabled: true,

                            // Text to display in label - default is null. Provide an array to display values on a new line
                            content: ["Hoje"],
                        },

                        // Mouse event handlers - be sure to enable the corresponding events in the
                        // annotation events array or the event handler will not be called.
                        // See https://developer.mozilla.org/en-US/docs/Web/Events for a list of
                        // supported mouse events.
                        onMouseenter: function (e) {},
                        onMouseover: function (e) {},
                        onMouseleave: function (e) {},
                        onMouseout: function (e) {},
                        onMousemove: function (e) {},
                        onMousedown: function (e) {},
                        onMouseup: function (e) {},
                        onClick: function (e) {},
                        onDblclick: function (e) {},
                        onContextmenu: function (e) {},
                        onWheel: function (e) {},
                    },
                    {
                        type: "line",

                        // optional drawTime to control layering, overrides global drawTime setting
                        drawTime: "afterDatasetsDraw",

                        // optional annotation ID (must be unique)
                        id: "a-line-3",

                        // set to 'horizontal' or 'vertical' to draw a vertical line
                        mode: "vertical",

                        // ID of the scale to bind onto
                        scaleID: "x-axis-0",

                        // Data value to draw the line at
                        value: 53,

                        // Optional value at which the line draw should end
                        //endValue: 1,

                        // Line color
                        borderColor: window.chartColors.simples,

                        // Line width
                        borderWidth: 2,

                        // Line dash
                        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
                        borderDash: [2, 2],

                        // Line Dash Offset
                        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
                        borderDashOffset: 5,

                        label: {
                            // Background color of label, default below
                            backgroundColor: window.chartColors.simples,

                            // Font family of text, inherits from global
                            fontFamily: "sans-serif",

                            // Font size of text, inherits from global
                            fontSize: 12,

                            // Font style of text, default below
                            fontStyle: "bold",

                            // Font color of text, default below
                            fontColor: "#fff",

                            // Padding of label to add left/right, default below
                            xPadding: 6,

                            // Padding of label to add top/bottom, default below
                            yPadding: 6,

                            // Radius of label rectangle, default below
                            cornerRadius: 6,

                            // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
                            position: "center",

                            // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
                            // For horizontal lines positioned left or right, negative values move
                            // the label toward the edge, and positive values toward the center.
                            xAdjust: 0,

                            // Adjustment along y-axis (top-bottom) of label relative to above number (can be negative)
                            // For vertical lines positioned top or bottom, negative values move
                            // the label toward the edge, and positive values toward the center.
                            yAdjust: 0,

                            // Whether the label is enabled and should be displayed
                            enabled: true,

                            // Text to display in label - default is null. Provide an array to display values on a new line
                            content: ["Amanhã"],
                        },
                    },
                ],
            },
        },
    };
    return config;
}

/**
 * Get Data for Chart base on Multiple days accumulated values
 * @param dataset     Chart dataset
 * @param LS          Label String
 * @param checks      Array Energia options checks
 * @param colors      Colors for chart
 */
function getChartData(dataset, LS, checks, colors, unit = "MWh") {
    var labels = [];
    var curvasCarga = dataset;

    var itemsAp = [];
    var itemsAm = [];
    var itemsRCp = [];
    var itemsRCm = [];
    var itemsRIp = [];
    var itemsRIm = [];
    // For each central
    curvasCarga.forEach((element) => {
        labels.push(element.date);
        itemsAp.push(element.ap);
        itemsAm.push(element.am);
        itemsRCp.push(element.rcp);
        itemsRCm.push(element.rcm);
        itemsRIp.push(element.rip);
        itemsRIm.push(element.rim);
    });

    if (Array.isArray(itemsAp) && itemsAp.length > 0) {
        let valItemAp = itemsAp.reduce(function (prev, current, index) {
            return parseFloat(prev) + parseFloat(current);
        });
        var itemDataAp = {
            label: LS + " Energia A+: " + parseFloat(valItemAp).toFixed(2) + " " + unit,
            backgroundColor: colors.colorAp,
            borderColor: colors.colorAp,
            data: itemsAp,
            fill: false,
        };
    }

    if (Array.isArray(itemsAm) && itemsAm.length > 0) {
        let valItemAm = itemsAm.reduce(function (prev, current, index) {
            return parseFloat(prev) + parseFloat(current);
        });
        var itemDataAm = {
            label: LS + " Energia A-: " + parseFloat(valItemAm).toFixed(2) + " " + unit,
            backgroundColor: colors.colorAm,
            borderColor: colors.colorAm,
            data: itemsAm,
            fill: false,
        };
    }

    if (Array.isArray(itemsRCp) && itemsRCp.length > 0) {
        let valItemRCp = itemsRCp.reduce(function (prev, current, index) {
            return parseFloat(prev) + parseFloat(current);
        });
        var itemDataRCp = {
            label: LS + " Energia Rc+: " + parseFloat(valItemRCp).toFixed(2) + " " + unit,
            fill: false,
            backgroundColor: colors.colorRCp,
            borderColor: colors.colorRCp,
            data: itemsRCp,
        };
    }

    if (Array.isArray(itemsRCm) && itemsRCm.length > 0) {
        let valItemRCm = itemsRCm.reduce(function (prev, current, index) {
            return parseFloat(prev) + parseFloat(current);
        });
        var itemDataRCm = {
            label: LS + " Energia Rc-: " + parseFloat(valItemRCm).toFixed(2) + " " + unit,
            fill: false,
            backgroundColor: colors.colorRCm,
            borderColor: colors.colorRCm,
            data: itemsRCm,
        };
    }

    if (Array.isArray(itemsRIp) && itemsRIp.length > 0) {
        let valItemRIp = itemsRIp.reduce(function (prev, current, index) {
            return parseFloat(prev) + parseFloat(current);
        });
        var itemDataRIp = {
            label: LS + " Energia Ri+: " + parseFloat(valItemRIp).toFixed(2) + " " + unit,
            fill: false,
            backgroundColor: colors.colorRIp,
            borderColor: colors.colorRIp,
            data: itemsRIp,
        };
    }

    if (Array.isArray(itemsRIm) && itemsRIm.length > 0) {
        let valItemRIm = itemsRIm.reduce(function (prev, current, index) {
            return parseFloat(prev) + parseFloat(current);
        });
        var itemDataRIm = {
            label: LS + " Energia Ri-: " + parseFloat(valItemRIm).toFixed(2) + " " + unit,
            fill: false,
            backgroundColor: colors.colorRIm,
            borderColor: colors.colorRIm,
            data: itemsRIm,
        };
    }

    dataset = [];
    if (checks.checkAp) dataset.push(itemDataAp);
    if (checks.checkAm) dataset.push(itemDataAm);
    if (checks.checkRCp) dataset.push(itemDataRCp);
    if (checks.checkRCm) dataset.push(itemDataRCm);
    if (checks.checkRIp) dataset.push(itemDataRIp);
    if (checks.checkRIm) dataset.push(itemDataRIm);

    data = { labels: labels, dataset: dataset };

    return data;
}
/**
 *
 * Build Chart Data
 * @param {Array}   centraisData      Data for Chart
 * @param {array}   checks            Energy Type to load
 * @param {string}  LS                Loading String
 * @param {string}  div               Name of canvas ID to put chart
 * @param {string}  title             Title of the chart
 * @param {string}  labelX            Label for x axis
 *
 */
function buildChart(centraisData, checks, LS, div, title, labelX, colors) {
    // Labels for chart
    var labels = [];

    // Datasets for chart
    var datasets = [];

    // get data for chart with colors and energy checked
    let result = getChartData(centraisData, LS, checks, colors);
    // fill chart labels
    labels = result.labels;
    // fill chart datasets
    datasets = datasets.concat(result.dataset);

    var config = getConfig("bar", labels, datasets, title, labelX);
    var context = document.getElementById(div).getContext("2d");
    var myChart = new Chart(context, config);
    return myChart;
}
/**
 *
 * Build Chart Data
 * @param {Array}   centraisData      Data for Chart
 * @param {array}   checks            Energy Type to load
 * @param {string}  LS                Loading String
 * @param {string}  div               Name of canvas ID to put chart
 * @param {string}  title             Title of the chart
 * @param {string}  labelX            Label for x axis
 *
 */
function buildModalChart(centraisData, checks, LS, div, title, labelX, colors) {
    // Labels for chart
    // centraldatapt and centraldataes has same labels
    var labels = [];

    // Datasets for chart
    var datasets = [];

    // get data for chart with colors and energy checked
    let result = getChartData(centraisData, LS, checks, colors);
    // fill chart labels
    labels = result.labels;
    // fill chart datasets
    datasets = datasets.concat(result.dataset);

    var config = getModalConfig("bar", labels, datasets, title, labelX);
    var context = document.getElementById(div).getContext("2d");
    var myChart = new Chart(context, config);
    return myChart;
}
/**
 *
 *Build Chart Data
 * @param {array} datasets
 * @param {array} colors
 * @param {array} labels
 * @param {string} div
 * @param {string} title
 * @returns
 */
function buildChartPie(datasets, colors, labels, div, title, unit = "MWh") {
    var config = {
        type: "doughnut",
        data: {
            datasets: [
                {
                    data: datasets,
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                },
            ],
            labels: labels,
        },
        options: {
            responsive: true,
            title: {
                display: true,
                text: title,
            },
            tooltips: {
                mode: "index",
                intersect: false,
                backgroundColor: window.grid_line_color,
                titleSpacing: 14,
                bodySpacing: 8,
                callbacks: {
                    label: function (tooltipItem, data) {
                        var label = data.labels[tooltipItem.index] || "";
                        label = label.split(":")[0];
                        var value = data.datasets[0].data[tooltipItem.index];
                        var item = " " + unit;
                        if (label) {
                            if (label.includes("Valor") || label.includes("€")) {
                                item = " €";
                            }
                        }
                        label += ": ";
                        label += parseFloat(value).toFixed(2) + item;
                        return label;
                    },
                },
            },
            hover: {
                mode: "nearest",
                intersect: true,
            },
        },
    };
    var context = document.getElementById(div).getContext("2d");
    var myChart = new Chart(context, config);
    return myChart;
}
/**
 * Configuration for chart
 * @param string    type    Type of chart
 * @param labels    array   Chart X point labels
 * @param datasets  array   Chart Datasets
 * @param string    labelX  Label for X chart
 *
 */
function getConfigBilateralMonth(type, labels, datasets, title, labelX) {
    config = {
        type: type,
        data: {
            labels: labels,
            datasets: datasets,
        },
        options: {
            responsive: true,
            title: {
                display: true,
                text: title,
            },
            tooltips: {
                mode: "index",
                intersect: false,
                backgroundColor: window.grid_line_color,
                titleSpacing: 14,
                bodySpacing: 8,
                callbacks: {
                    label: function (tooltipItem, data) {
                        var label = data.datasets[tooltipItem.datasetIndex].label || "";
                        label = label.split(":")[0];
                        var item = "";
                        if (label) {
                            if (label.includes("Valor") || label.includes("€")) {
                                item = " €";
                            }
                            if (label.includes("Energia")) {
                                item = " " + unit;
                            }
                            label += ": ";
                        }
                        label += parseFloat(tooltipItem.yLabel).toFixed(2) + item;
                        return label;
                    },
                },
            },
            hover: {
                mode: "nearest",
                intersect: true,
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: labelX,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.grid_line_color,
                            drawTicks: true,
                        },
                    },
                ],
                yAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: "MWh",
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                        gridLines: {
                            zeroLineColor: window.axes_line_color,
                            color: window.grid_line_color,
                            drawTicks: true,
                        },
                    },
                ],
            },
        },
    };
    return config;
}

let download = (content, filename, filetype) => {
    var typeXls = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    var typePdf = "application/pdf";
    var type = "";
    switch (filetype) {
        case "pdf":
            type = typePdf;
            break;
        case "xls":
            type = typeXls;
            break;
        default:
            type = typePdf;
            break;
    }
    var elem = document.getElementById("downloadlink");
    if (elem !== null) {
        elem.parentNode.removeChild(elem);
    }
    var byteCharacters = atob(content);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], { type: type });

    let uriContent = URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.setAttribute("id", "downloadlink");
    link.setAttribute("href", uriContent);
    link.setAttribute("download", filename);
    return link;
};

let getBase64File = (content, filename, filetype) => {
    var typeXlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    var typeXls = "application/vnd.ms-excel";
    var typePdf = "application/pdf";
    var type = "";
    switch (filetype) {
        case "pdf":
            type = typePdf;
            break;
        case "xlsx":
            type = typeXlsx;
            break;
        case "xls":
            type = typeXls;
            break;
        default:
            type = filetype;
            break;
    }
    $("#downloadfile").remove();
    var byteCharacters = atob(content);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], { type: type });

    let uriContent = URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.setAttribute("id", "downloadfile");
    link.setAttribute("href", uriContent);
    link.setAttribute("download", filename);
    let event = new MouseEvent("click");
    link.dispatchEvent(event);
};

function sendForm(form, formData, callback) {
    let btn = form.find('button[type="submit"]');
    let oldhtml = btn.html();
    let loading = '<i aria-hidden="true" class="fa fa-spinner fa-spin"></i>  ';
    btn.html(loading + oldhtml);
    btn.addClass("inactiveLink");
    $.ajax({
        method: "POST",
        type: "POST",
        url: "/admin/update",
        data: formData,
        enctype: "multipart/form-data",
        cache: false,
        contentType: false,
        processData: false,
        dataType: "json",
        success: function (data) {
            btn.html(oldhtml);
            btn.removeClass("inactiveLink");
            if (data.error) {
                toastr.warning(data.error, "Aviso:", {
                    onHidden: function () {
                        if (data.session === false) {
                            window.location.replace("/admin");
                        }
                    },
                });
                return false;
            }

            if (data.success === true) {
                callback(data, form);
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            btn.html(oldhtml);
            btn.removeClass("inactiveLink");
            let info = xhr.status + "<br>" + thrownError;
            toastr.error(info, "Error:");
            return false;
        },
    });
}

function sendRequestTimer(btn, type, params, callback) {
    var countTime = 0;
    var spinner = `<span class="spinner-grow spinner-grow-sm"></span> Loading...<span class="ml-2" id="mytimer${type}"></span>`;
    var oldHtml = btn.html();

    btn.attr("disabled", true);
    btn.html(spinner);

    var mytimer = setInterval(function () {
        countTime++;
        $("#mytimer" + type).html(countTime + " s");
    }, 1000);

    $.ajax({
        type: "POST",
        url: "/admin/update",
        data: params,
        dataType: "json",
        success: function (data) {
            clearInterval(mytimer);
            btn.html(oldHtml);
            btn.attr("disabled", false);

            if (data.error) {
                toastr.warning(data.error, "Aviso:", {
                    onHidden: function () {
                        if (data.session === false) {
                            window.location.replace("/admin");
                        }
                    },
                });
                return false;
            }

            if (data.success === true) {
                callback(data);
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            clearInterval(mytimer);
            btn.html(oldHtml);
            btn.attr("disabled", false);
            let info = xhr.status + "<br>" + thrownError;
            toastr.error(info, "Error:");
            return false;
        },
    });
}

function sendRequestTimerURL(url, btn, type, params, callback) {
    var countTime = 0;
    var spinner = `<span class="spinner-grow spinner-grow-sm"></span> Loading...<span class="ml-2" id="mytimer${type}"></span>`;
    var oldHtml = btn.html();

    btn.attr("disabled", true);
    btn.html(spinner);

    const content = btn.closest(".card");

    var mytimer = setInterval(function () {
        countTime++;
        $("#mytimer" + type).html(countTime + " s");
    }, 1000);

    $.ajax({
        type: "POST",
        url: url,
        data: params,
        dataType: "json",
        success: function (data) {
            clearInterval(mytimer);
            btn.html(oldHtml);
            btn.attr("disabled", false);
            content.unblock();

            if (data.error) {
                toastr.warning(data.error, "Aviso:");
                return false;
            }

            if (data.success === true) {
                callback(data);
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            clearInterval(mytimer);
            btn.html(oldHtml);
            btn.attr("disabled", false);
            content.unblock();
            let info = xhr.status + "<br>" + thrownError;
            toastr.error(info, "Error:");
            return false;
        },
    });
}

function getDataTableOptions(orientation = null) {
    let setOrientation = "landscape";
    if (orientation && (orientation == "landscape" || orientation == "portrait")) {
        setOrientation = orientation;
    }
    return {
        dom: "lBfrtip",
        buttons: [
            { extend: "copy", className: "btn btn-copy" },
            { extend: "csv", className: "btn btn-csv" },
            { extend: "excelHtml5", className: "btn btn-excel", exportOptions: { columns: ":visible" } },
            {
                extend: "pdfHtml5",
                className: "btn btn-pdf",
                orientation: setOrientation,
                pageSize: "A4",
                customize: function (doc) {
                    doc.pageMargins = [15, 10, 10, 10];
                    doc.defaultStyle.fontSize = 6;
                    doc.styles.tableHeader.fontSize = 7;
                    doc.styles.tableFooter.fontSize = 7;
                    doc.styles.title.fontSize = 10;
                },
                footer: true,
            },
            { extend: "print", className: "btn btn-print" },
        ],
        language: {
            decimal: ".",
            emptyTable: "Sem Dados Disponíveis",
            info: "Mostrar _START_ até _END_ de _TOTAL_ registos",
            infoEmpty: "Sem Registos",
            infoFiltered: "(filtro de um total de _MAX_ registos)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Mostar _MENU_ Registos",
            loadingRecords: "Loading...",
            processing: "Processar...",
            search: "",
            zeroRecords: "Não foram encontrados registos",
            paginate: {
                previous: "&lt;",
                next: "&gt;",
                first: "&lt;&lt;",
                last: "&gt;&gt;",
            },
            aria: {
                sortAscending: ": Ordenar Ascendente",
                sortDescending: ": Ordenar Descendente",
            },
        },
        paging: false,
        responsive: false,
    };
}

function getSimpleDataTableOptions() {
    return {
        dom: "lBfrtip",
        buttons: [],
        language: {
            decimal: ".",
            emptyTable: "Sem Dados Disponíveis",
            info: "Mostrar _START_ até _END_ de _TOTAL_ registos",
            infoEmpty: "Sem Registos",
            infoFiltered: "(filtro de um total de _MAX_ registos)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Mostar _MENU_ Registos",
            loadingRecords: "Loading...",
            processing: "Processar...",
            search: "",
            zeroRecords: "Não foram encontrados registos",
            paginate: {
                previous: "&lt;",
                next: "&gt;",
                first: "&lt;&lt;",
                last: "&gt;&gt;",
            },
            aria: {
                sortAscending: ": Ordenar Ascendente",
                sortDescending: ": Ordenar Descendente",
            },
        },
        paging: false,
    };
}

function sendFormTo(url, formData, callback = null) {
    // Block Screen
    let blockOptions = getBlockOptions();
    $.blockUI(blockOptions);
    // Prevent other form button to be pushed
    let btn = $("body").find("button[type=submit]");
    btn.attr("disabled", true);
    // Set activity
    $.ajax({
        method: "POST",
        type: "POST",
        url: url,
        data: formData,
        enctype: "multipart/form-data",
        cache: false,
        contentType: false,
        processData: false,
        dataType: "json",
        success: function (data) {
            // Unblock Screen
            $.unblockUI();
            // Release buttons
            btn.attr("disabled", false);
            // Remove Spinning
            $(".btn-spin").removeClass("btn-spin");

            /**
             * Check Errors
             */
            if (data.error) {
                let container = btn.closest(".card").find(".card-body");
                if (container.length > 0) {
                    container.unblock();
                }
                if (btn.closest(".card").length > 0) {
                    btn.closest(".card").unblock();
                }
                toastr.warning(data.error, "Verificar:", {
                    timeOut: 10000,
                    onHidden: function () {
                        if (data.session === false) {
                            const currentUrl = window.location.href;
                            if (currentUrl.indexOf("admin") !== -1) {
                                window.location.replace("/admin");
                            } else if (currentUrl.indexOf("partner") !== -1) {
                                window.location.replace("/partner");
                            } else {
                                window.location.replace("/");
                            }
                        }
                    },
                });
                return false;
            }

            /**
             * All good send callback
             */
            if (data.success === true) {
                callback(data);
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            $(".btn-spin").removeClass("btn-spin");
            let info = xhr.status + "<br>" + thrownError;
            if (xhr.statusText !== undefined) {
                info = xhr.status + "<br>" + xhr.statusText;
            }
            if (xhr.responseJSON !== undefined && xhr.responseJSON.detail !== undefined) {
                info = "<small>" + xhr.responseJSON.detail + "</small>";
            }
            let title = "Erro no envio";
            if (xhr.responseJSON !== undefined && xhr.responseJSON.title !== undefined) {
                title = "ERROR " + xhr.status + " :: " + xhr.responseJSON.title;
            }

            toastr.error(info, title, {
                onHidden: function () {
                    // Unblock Screen
                    $.unblockUI();
                    // Release buttons
                    btn.attr("disabled", false);
                },
            });
            return false;
        },
    });
}

function sendRequest(btn, params, callback) {
    let defaultHtml = '<i class="fa fa-refresh"></i>';
    let originalHtml = btn.html();
    if (originalHtml.indexOf("spinner") !== -1) {
        originalHtml = defaultHtml;
    }
    var timestamp = new Date().getUTCMilliseconds();
    let countTime = 0;

    let spinTemplate = `<span class="spinner-grow spinner-grow-sm"></span> <span id="mytimer${timestamp}"></span>`;

    btn.attr("disabled", true);
    //btn.addClass("fa-spin");
    btn.html(spinTemplate);

    var mytimer = setInterval(function () {
        countTime++;
        $("#mytimer" + timestamp).html(countTime + " s");
    }, 1000);

    $.ajax({
        type: "POST",
        url: "/admin/update",
        data: params,
        dataType: "json",
        success: function (data) {
            btn.removeClass("fa-spin");
            $.unblockUI();

            /**
             * Restore Button Initial Values
             */
            clearInterval(mytimer);
            btn.html(originalHtml);
            btn.attr("disabled", false);

            /**
             * Check Errors
             */
            if (data.error) {
                let container = btn.closest(".card").find(".card-body");
                if (container.length > 0) {
                    container.unblock();
                }
                if (btn.closest(".card").length > 0) {
                    btn.closest(".card").unblock();
                }
                toastr.warning(data.error, "Verificar:", {
                    timeOut: 10000,
                    onHidden: function () {
                        if (data.session === false) {
                            window.location.replace("/admin");
                        }
                    },
                });
                return false;
            }

            /**
             * All good send callback
             */
            if (data.success === true) {
                callback(data);
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            $.unblockUI();
            let container = btn.closest(".card").find(".card-body");
            if (container.length > 0) {
                container.unblock();
            }
            if (btn.closest(".card").length > 0) {
                btn.closest(".card").unblock();
            }
            btn.removeClass("fa-spin");
            btn.unblock();
            clearInterval(mytimer);
            btn.html(originalHtml);
            btn.attr("disabled", false);
            let info = xhr.status + "<br>" + thrownError;
            toastr.error(info, "Erro no envio");
            return false;
        },
    });
}

function sendRequestURL(url, btn, params, callback) {
    let defaultHtml = '<i class="fa fa-refresh"></i>';
    let originalHtml = btn.html();
    if (originalHtml.indexOf("spinner") !== -1) {
        originalHtml = defaultHtml;
    }
    var timestamp = new Date().getUTCMilliseconds();
    let countTime = 0;

    let spinTemplate = `<span class="spinner-grow spinner-grow-sm"></span> <span id="mytimer${timestamp}"></span>`;

    btn.attr("disabled", true);
    btn.html(spinTemplate);

    var mytimer = setInterval(function () {
        countTime++;
        $("#mytimer" + timestamp).html(countTime + " s");
    }, 1000);

    $.ajax({
        type: "POST",
        url: url,
        data: params,
        dataType: "json",
        success: function (data) {
            btn.removeClass("fa-spin");
            $.unblockUI();
            /**
             * Restore Button Initial Values
             */
            clearInterval(mytimer);
            btn.html(originalHtml);
            btn.attr("disabled", false);

            /**
             * Check Errors
             */
            if (data.error) {
                let container = btn.closest(".card").find(".card-body");
                if (container.length > 0) {
                    container.unblock();
                }
                if (btn.closest(".card").length > 0) {
                    btn.closest(".card").unblock();
                }
                toastr.warning(data.error, "Verificar:", {
                    timeOut: 10000,
                    onHidden: function () {
                        if (data.session === false) {
                            const currentUrl = window.location.href;
                            if (currentUrl.indexOf("admin") !== -1) {
                                window.location.replace("/admin");
                            } else if (currentUrl.indexOf("partner") !== -1) {
                                window.location.replace("/partner");
                            } else {
                                window.location.replace("/");
                            }
                        }
                    },
                });
                return false;
            }

            /**
             * All good send callback
             */
            if (data.success === true) {
                callback(data);
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            clearInterval(mytimer);
            btn.html(originalHtml);
            btn.attr("disabled", false);
            // Unblock Screen
            $.unblockUI();
            let info = xhr.status + "<br>" + thrownError;
            if (xhr.statusText !== undefined) {
                info = xhr.status + "<br>" + xhr.statusText;
            }
            if (xhr.responseJSON !== undefined && xhr.responseJSON.detail !== undefined) {
                info = "<small>" + xhr.responseJSON.detail + "</small>";
            }
            let title = "Erro no envio";
            if (xhr.responseJSON !== undefined && xhr.responseJSON.title !== undefined) {
                title = "ERROR " + xhr.status + " :: " + xhr.responseJSON.title;
            }
            toastr.error(info, title);
            return false;
        },
    });
}

function isJsonObject(strData) {
    try {
        JSON.parse(strData);
    } catch (e) {
        return false;
    }
    return true;
}

function getAlert(id, type, message, callback = null) {
    let timeout = 15000;
    if (type == "success") {
        timeout = 3000;
    }
    let iconDanger = '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>';
    let iconWarning = '<i class="fas fa-info-circle" aria-hidden="true"></i>';
    let iconSuccess = '<i class="fa fa-check-square-o" aria-hidden="true"></i>';
    let msgSuccess = "Success";
    let msgWarning = "Warning";
    let msgDanger = "Error";
    let icon = "";
    let title = type;
    switch (type) {
        case "success":
            icon = iconSuccess;
            title = msgSuccess;
            break;
        case "warning":
            icon = iconWarning;
            title = msgWarning;
            break;
        case "danger":
            icon = iconDanger;
            title = msgDanger;
            break;
        default:
            break;
    }

    let html = `
  <div id="alert-${id}" class="alert alert-${type}  alert-dismissible" style="clear:both">
  <h4 class="alert-heading">${icon} ${title}</h4>
  <p class="mb-0">${message}</p>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" style="top:0;right:5px">
      <span aria-hidden="true">&times;</span>
  </button>
</div>`;
    setTimeout(function () {
        $("#alert-" + id).hide("slow", function () {
            $(this).remove();
            if (callback) {
                callback();
            }
        });
    }, timeout);
    return html;
}

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

function toggledropdown(btn) {
    let target = btn.data("target");
    btn.toggleClass("open");
    btn.next(target).slideToggle("slow");
}
$(document.body).on("click", ".toggledropdown", function () {
    toggledropdown($(this));
});

function clearOptions(element) {
    let options = element.find("option");
    options.each(function (index) {
        option = $(this);
        if (option.val() == "") {
            option.prop("selected", true);
        } else {
            option.remove();
        }
    });
}

function getBlockOptions(count = null) {
    let defaultColor = "#10163a";
    if ($("body").hasClass("brand-layout")) {
        defaultColor = "#284d1e";
    }
    if ($("body").hasClass("semi-dark-layout")) {
        defaultColor = "#284d1e";
    }

    let message = '<div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div></div>';
    if (count) {
        message =
            '<div class="feather icon-refresh-cw icon-spin font-medium-2 text-primary"></div><div class="counter">' +
            count +
            "</div>";
    }

    return {
        message: message,
        overlayCSS: {
            backgroundColor: defaultColor,
            cursor: "wait",
            opacity: 0.8,
        },
        css: {
            padding: 0,
            margin: 0,
            width: "30%",
            top: "40%",
            left: "35%",
            textAlign: "center",
            color: "#000",
            border: "none",
            backgroundColor: "none",
            cursor: "wait",
        },
    };
}

function blockElementTimeOut(element, timeout) {
    // Block Element
    element.block(getBlockOptions(timeout));

    const ti = setInterval(function (e) {
        timeout--;
        element.find(".counter").html(timeout);
    }, 1000);

    setTimeout(function (e) {
        clearInterval(ti);
        element.unblock();
    }, timeout * 1000);
}

const docDropzoneOption = function (params, callback = null) {
    return {
        params: params,
        method: "post",
        url: "/admin/update",
        acceptedFiles:
            "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
        paramName: "files", // The name that will be used to transfer the file
        maxFilesize: 8, // MB
        maxFiles: 10,
        parallelUploads: 10,
        dictDefaultMessage: "Arrastar para aqui ou click para carregar ficheiro",
        dictRemoveFile: "Remover Ficheiro",
        dictInvalidFileType: "Tipo de ficheiro inválido",
        uploadMultiple: true,
        autoProcessQueue: false,
        addRemoveLinks: true,
        init: function () {
            dropzone = this;
            dropzone.on("sending", function (data, xhr, formData) {
                for (var param in this.params) {
                    formData.append(param, this.params[param]);
                }
            });
            dropzone.on("success", function (file, response) {
                var data = JSON.parse(response);

                /**
                 * Check Errors
                 */
                if (data.error) {
                    toastr.warning(data.error, "Verificar:", {
                        onHidden: function () {
                            if (data.session === false) {
                                window.location.replace("/admin");
                            }
                        },
                    });
                    return false;
                }

                if (data.success) {
                    this.removeAllFiles();

                    if (callback) {
                        callback(data);
                    }
                }
            });
            dropzone.on("totaluploadprogress", function (progress) {
                $("#counter").html(progress + "%");
            });

            dropzone.on("error", function (file, message, xhr) {
                toastr.error(message, "Error on file to upload " + file.name);
            });

            dropzone.on("addedfile", function (file) {
                if (file.type.match(/pdf.*/)) {
                    this.emit("thumbnail", file, "/images/pdf.png");
                }
                if (file.type.match(/excel.*/)) {
                    this.emit("thumbnail", file, "/images/excel.png");
                }
                if (file.type.match(/spreadsheetml.*/)) {
                    this.emit("thumbnail", file, "/images/excel.png");
                }
                if (file.type.match(/word.*/)) {
                    this.emit("thumbnail", file, "/images/word.png");
                }
            });
        },
    };
};

function getCurrenteDateTime() {
    var today = new Date();

    var date =
        today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2);

    var time =
        ("0" + today.getHours()).slice(-2) +
        ":" +
        ("0" + today.getMinutes()).slice(-2) +
        ":" +
        ("0" + today.getSeconds()).slice(-2);

    return date + " " + time;
}

function getSocketIo() {
    if (window.socketurl) {
        const socket = io(window.socketurl, {
            withCredentials: true,
        });
        return socket;
    }
    return null;
}

function delay(callback, ms) {
    var timer = 0;
    return function () {
        var context = this,
            args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

function formReadOnly(form) {
    form.find("input").attr("readonly", true);
    form.find("input").attr("disabled", true);

    form.find("select").prop("disabled", true);

    form.find("button").prop("disabled", true);
}

function getDateDaysDiff(dateini, dateend) {
    var date1 = new Date(dateini);
    var date2 = new Date(dateend);

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    return Math.floor(Difference_In_Time / (1000 * 3600 * 24));
}
